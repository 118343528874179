<template>
  <a-drawer width='35%' :label-col='4' :wrapper-col='14' :visible='open' @close='onClose'>
    <a-divider orientation='left'>
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref='form' :model='form' :rules='rules'>
      <a-form-model-item label='用户id，外键' prop='userId'
      >
        <a-input v-model='form.userId' placeholder='请输入用户id，外键' />
      </a-form-model-item>
      <a-form-model-item label='反馈类型' prop='feedbackType'
      >
      </a-form-model-item>
      <a-form-model-item label='反馈内容' prop='content'
      >
        <editor v-model='form.content' />
      </a-form-model-item>
      <a-form-model-item label='反馈图片地址' prop='imgUrl'
      >
        <a-input v-model='form.imgUrl' placeholder='请输入内容' type='textarea' allow-clear />
      </a-form-model-item>
      <a-form-model-item label='处理状态，0-待处理 1-已处理' prop='handleFlag'
      >
        <a-input v-model='form.handleFlag' placeholder='请输入处理状态，0-待处理 1-已处理' />
      </a-form-model-item>
      <a-form-model-item label='处理意见' prop='handleContent'
      >
        <editor v-model='form.handleContent' />
      </a-form-model-item>
      <a-form-model-item label='处理时间' prop='handleTime'
      >
        <a-date-picker style='width: 100%' v-model='form.handleTime' format='YYYY-MM-DD HH:mm:ss'
                       allow-clear />
      </a-form-model-item>
      <a-form-model-item label='分类名称' prop='cateName'
      >
        <a-input v-model='form.cateName' placeholder='请输入分类名称' />
      </a-form-model-item>
      <a-form-model-item label='备注' prop='remark'
      >
        <a-input v-model='form.remark' placeholder='请输入内容' type='textarea' allow-clear />
      </a-form-model-item>
      <div class='bottom-control'>
        <a-space>
          <a-button type='primary' :loading='submitLoading' @click='submitForm'>
            保存
          </a-button>
          <a-button type='dashed' @click='cancel'>
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getFeedback, addFeedback, updateFeedback } from '@/api/user/feedback'
import Editor from '@/components/Editor'

export default {
  name: 'CreateForm',
  props: {}
  ,
  components: {
    Editor
  }
  ,
  data() {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        userId: null,

        feedbackType: null,

        content: null,

        imgUrl: null,

        handleFlag: null,

        handleContent: null,

        handleTime: null,

        cateName: null,

        createTime: null,

        remark: null

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          {
            required: true, message: '用户id，外键不能为空', trigger: 'blur'
          }
        ],
        feedbackType: [
          {
            required: true, message: '反馈类型不能为空', trigger: 'change'
          }
        ],
        handleFlag: [
          {
            required: true, message: '处理状态，0-待处理 1-已处理不能为空', trigger: 'blur'
          }
        ],
        handleContent: [
          {
            required: true, message: '处理意见不能为空', trigger: 'blur'
          }
        ],
        handleTime: [
          {
            required: true, message: '处理时间不能为空', trigger: 'blur'
          }
        ],
        cateName: [
          {
            required: true, message: '分类名称不能为空', trigger: 'blur'
          }
        ]
      }
    }
  }
  ,
  filters: {}
  ,
  created() {
  }
  ,
  computed: {}
  ,
  watch: {}
  ,
  mounted() {
  }
  ,
  methods: {
    onClose() {
      this.open = false
    }
    ,
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    }
    ,
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        feedbackType: null,
        content: null,
        imgUrl: null,
        handleFlag: null,
        handleContent: null,
        handleTime: null,
        cateName: null,
        createTime: null,
        remark: null
      }
      this.$nextTick(() => {
        if (this.$refs.form) {
          this.$refs.form.resetFields()
        }
      })
    }
    ,
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    }
    ,
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getFeedback({ 'id': id }).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    }
    ,
    /** 提交按钮 */
    submitForm: function() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateFeedback(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addFeedback(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

import request from '@/utils/request'


// 查询用户反馈列表
export function listFeedback(query) {
    return request({
        url: '/user/user-feedback/list',
        method: 'get',
        params: query
    })
}

// 查询用户反馈分页
export function pageFeedback(query) {
    return request({
        url: '/user/user-feedback/page',
        method: 'get',
        params: query
    })
}

// 查询用户反馈详细
export function getFeedback(data) {
    return request({
        url: '/user/user-feedback/detail',
        method: 'get',
        params: data
    })
}

// 新增用户反馈
export function addFeedback(data) {
    return request({
        url: '/user/user-feedback/add',
        method: 'post',
        data: data
    })
}

// 修改用户反馈
export function updateFeedback(data) {
    return request({
        url: '/user/user-feedback/edit',
        method: 'post',
        data: data
    })
}

// 删除用户反馈
export function delFeedback(data) {
    return request({
        url: '/user/user-feedback/delete',
        method: 'post',
        data: data
    })
}
